<template>
  <v-layout class="main-wrapper" column>
    <v-app-bar class="header-bar" fixed flat>
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        class="btn-icon ml-2"
        icon
        @click="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-img
        v-else
        :src="require('@/assets/jp2pay/logomark.png')"
        class="ml-16"
        max-width="2rem"
        contain
      />

      <v-spacer />

      <div v-if="$vuetify.breakpoint.mdAndUp">
        <router-link
          v-for="(item, i) in _routes"
          v-slot="{ href, navigate, isActive }"
          class="text-decoration-none font-weight-regular pointer"
          :class="{ 'mr-6': i < _routes.length - 1 }"
          :to="item.path"
          :key="i"
        >
          <span
            :class="{ 'font-weight-bold': isActive }"
            :href="href"
            @click="navigate"
          >
            {{ item.label }}
          </span>
        </router-link>
      </div>

      <v-spacer />

      <v-btn
        class="btn mr-md-16 rounded-lg"
        color="primary"
        href="https://app.jp2pay.com"
      >
        Login
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      class="side-drawer rounded-r-xl"
      color="neutral3"
      floating
      app
    >
      <div class="d-flex flex-column fill-height">
        <div class="d-flex align-center pa-4">
          <v-img
            :src="require('@/assets/jp2pay/logomark.png')"
            class="mr-2"
            max-width="1.75rem"
            height="1.75rem"
            contain
          />

          <span class="text-h6 font-weight-bold"> JP2PAY </span>
        </div>

        <v-list class="py-0" color="transparent" nav>
          <router-link
            v-for="(item, i) in _routes"
            v-slot="{ href, navigate, isActive }"
            class="mb-1"
            :to="item.path"
            :key="i"
            custom
          >
            <v-list-item class="router-item" :href="href" @click="navigate">
              <v-list-item-title :class="{ 'primary--text': isActive }">
                {{ item.label }}
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list>
      </div>
    </v-navigation-drawer>

    <router-view class="pb-16" />

    <section class="neutral2">
      <v-container class="py-16">
        <v-row justify="center">
          <v-col cols="10" sm="4" md="3" class="d-flex flex-column">
            <div class="text-h5 font-weight-bold mb-4">Link diretos</div>

            <div v-for="(item, i) in quickLinks" class="mb-2" :key="i">
              <router-link
                v-if="item.path"
                class="white--text text-decoration-none"
                :to="item.path"
              >
                {{ item.label }}
              </router-link>

              <a
                v-else
                class="white--text text-decoration-none"
                target="_blank"
                :href="item.url"
              >
                {{ item.label }}
              </a>
            </div>
          </v-col>

          <v-col cols="10" sm="4" md="3" class="d-flex flex-column">
            <div class="text-h5 font-weight-bold mb-4">Suporte</div>

            <a
              v-for="(item, i) in support"
              class="mb-2 white--text text-decoration-none"
              target="_blank"
              :href="item.url"
              :key="i"
            >
              {{ item.label }}
            </a>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-footer color="neutral2">
      <v-container>
        <div class="d-flex flex-column flex-md-row justify-center align-center">
          <div class="mb-4 mb-md-0">
            ©{{ new Date().getFullYear() }} JP2PAY. Todos os direitos reservados
          </div>

          <v-spacer />

          <div>
            <v-btn
              v-for="(item, i) in socialMedia"
              target="_blank"
              :key="i"
              :href="item.url"
              icon
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-footer>
  </v-layout>
</template>

<script>
export default {
  name: "Main",

  data() {
    return {
      drawer: false,
      quickLinks: [
        {
          label: "Home",
          path: "/home",
        },
        {
          label: "Quem somos",
          path: "/about",
        },
        // TODO: Create a new design and make the page available again
        // {
        //   label: "Como Funciona",
        //   path: "/how-works",
        // },
        {
          label: "Contato",
          url: "https://wa.me/" + process.env.VUE_APP_PHONE_NUMBER,
        },
      ],
      support: [
        {
          label: "White Paper Bitcoin PB/BR",
          url: process.env.VUE_APP_STORAGE_URL + "/white_paper.pdf",
        },
        {
          label: "Contábil",
          url: "https://contiliza.com.br",
        },
        {
          label: "Jurídico",
          url: "https://mykyc.com.br",
        },
        {
          label: "Suporte",
          url: "https://wa.me/" + process.env.VUE_APP_PHONE_NUMBER,
        },
      ],
      socialMedia: [
        {
          icon: "mdi-facebook",
          url: "https://www.facebook.com/jessp2p",
        },
        {
          icon: "mdi-instagram",
          url: "https://www.instagram.com/jessilimal",
        },
        {
          icon: "mdi-telegram",
          url: "https://t.me/jessilimal",
        },
      ],
    };
  },

  computed: {
    _routes() {
      return [
        { label: "Comprar BTC", path: "/home" },
        { label: "Quem somos", path: "/about" },
        // TODO: Create a new design and make the page available again
        // { label: "Como funciona", path: "/how-works" },
      ];
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
