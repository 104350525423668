import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Main from "../modules/main/Main.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Home" },
  },
  {
    path: "/",
    name: "Main",
    redirect: { name: "Home" },
    component: Main,
    children: [
      {
        path: "home",
        name: "Home",
        meta: { requiresAuth: true },
        component: () => import("@/modules/main/views/home/Home.vue"),
      },
      {
        path: "about",
        name: "About",
        meta: { requiresAuth: true },
        component: () => import("@/modules/main/views/about/About.vue"),
      },
      // TODO: Create a new design and make the page available again
      // {
      //   path: "how-works",
      //   name: "HowWorks",
      //   meta: { requiresAuth: true },
      //   component: () => import("@/modules/main/views/howWorks/HowWorks.vue"),
      // },
    ],
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
