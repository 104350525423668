<template>
  <v-app id="app">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
html {
  background: $neutral-1;
}

#app {
  background: $neutral-1;
}
</style>
