import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";
import "@fortawesome/fontawesome-free/css/all.css";
import _colors from "../styles/variables/_colors.module.scss";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: _colors.primary,
        secondary: _colors.secondary,
        accent: _colors.accent,
        error: _colors.error,
        info: _colors.info,
        success: _colors.success,
        warning: _colors.warning,
        neutral: _colors.neutral,
        neutral1: _colors.neutral1,
        neutral2: _colors.neutral2,
        neutral3: _colors.neutral3,
        neutral4: _colors.neutral4,
        neutral5: _colors.neutral5,
        neutral6: _colors.neutral6,
      },
    },
  },
  icons: {
    iconfont: "fa",
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
