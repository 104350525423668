import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: process.env.VUE_APP_PROJECT_KEY,
  storage: window.localStorage,
  modules: [],
});

export default new Vuex.Store({
  state: {},

  getters: {},

  mutations: {},

  actions: {},

  modules: {},

  plugins: [vuexLocal.plugin],
});
